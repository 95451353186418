import { AccountCircle, Facebook, Google, Lock, LockPerson, Twitter } from '@mui/icons-material'
import { Alert, Box, Button, Container, Divider, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const ForgetPage = () => {

  const navigate = useNavigate()

  const [values, setValues] = useState({
    email:'',
  })

    axios.defaults.withCredentials = true;
    const handleChange = (e) =>{
        e.preventDefault();
        axios.post('http://localhost:5000/forgetpass', values)
        .then(res => {
            console.log(res)
            if (res.data.Status === "Success") {
                 navigate('/editPass')
            } else {
                alert(res.data.Message) 
            }
        })
        .catch(err => console.log("Error in Front Login Page", err))
    }

  return (
    <div style={{ height: '100vh', overflow: 'hidden', background: 'linear-gradient(to right bottom, #32B1E4, #CC14E2)' }}>
      <Container maxWidth='lg'>
        <center>
          <Grid item container xs={12} alignItems='center' justifyContent='center' mt={5}>
            <Box sx={{ bgcolor: 'white', p: 5, borderRadius: 10, height: 600, width: 400, boxShadow: 5 }}>
              <Grid item xs={12} >
                <Typography variant='h4' fontWeight='bold' fontFamily='sans-serif'>Check Your Email</Typography>
                <Divider variant='middle' />
              </Grid>

              <form onSubmit={handleChange}>
                
                <Grid item xs={12} mt={5}>
                    <LockPerson fontSize='large'/>
                </Grid>

                <Grid item xs={12} mt={5}>
                <FormControl variant='standard'>
                    <InputLabel >Dealer Eamil or Mobile No</InputLabel>
                    <Input
                      required
                      name={'email' || 'mobile'}
                      placeholder='Email or Mobile No'
                      fullWidth
                      onChange={e=>setValues({...values, email:e.target.value})}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      } />
                 
                  </FormControl>
                </Grid>
     
                <Grid item xs={12} mt={5}>
                  <Button type='submit' variant='contained' fullWidth sx={{ borderRadius: 10, background: 'linear-gradient(to bottom right, #1CC3E2, #CB2BF9)', width: 240, "&:hover": { background: 'linear-gradient(to bottom right, #CB2BF9, #1CC3E2)' } }}>Check</Button>
                </Grid>
              </form>
              <Grid item xs={12} mt={2}>
                <Typography variant='subtitle1'><Button variant='text' onClick={() => navigate('/login')}>Back To Login</Button></Typography>
              </Grid>
            </Box>
          </Grid>
        </center>
      </Container>
    </div>
  )
}

export default ForgetPage