import { AccountCircle ,Email , Lock, Phone, } from '@mui/icons-material'
import { Alert, Box, Button, Card, Container, Divider, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, Snackbar, Typography } from '@mui/material'
import axios from 'axios'
import { useFormik } from 'formik'
import React, { useState } from 'react';
import "./login.css"
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup' 

const Signin = () => {

  const initialvalues = {
    fname:'',
    lname:'',
    email:'',
    mobile:'',
    password:'',
    cpassword:''
  }

  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  // const [values, setValues] = useState(initialvalues)

  const formik = useFormik({

    initialValues: initialvalues,
  
    validationSchema:yup.object({
      fname:yup.string()
      .required('Enter your first name'),
      lname:yup.string()
      .required('Enter you last name'),
      mobile : yup.string()
      .required('Please enter mobile no')
      .min(10, "Mobile No must be 10 digits")
      .max(10, "Mobile No must be 10 digits"),
      email:yup.string()
      .required('Please enter email')
      .email('Please enter valid email'),
      password:yup.string()
      .required('Please enter password')
      .min(8, 'Password must be 8 characters long')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol'),
      cpassword:yup.string()
      .required('please re-type your password')
      .oneOf([yup.ref("password")], "Passwords does not match"),
    }),
    onSubmit:(userDetails)=>{
      axios.post("http://localhost:5000/dealer", userDetails)
      .then(res=>{
        if (res.statusText === 'OK') {
          console.log(userDetails);
           setOpen(true)
           setTimeout(() => {
            navigate('/login')
          }, 3000);
        } else {
          console.log('Register Failred')
        }
      })
      .catch(err=>console.log(err))

    }
  })
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        background: "linear-gradient(to right bottom, #32B1E4, #CC14E2)",
      }}
    >
      <Container maxWidth="lg">
        <center>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            mt={3}
          >
            <Card
              sx={{
                bgcolor: "white",
                borderRadius: 10,
                width: 400,
                boxShadow: 5,
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Grid item xs={12} mt={2}>
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    fontFamily="sans-serif"
                  >
                    Dealer Signup
                  </Typography>
                  <Divider variant="middle" />
                </Grid>

                <Grid item xs={12} mt={5}>
                  <FormControl variant="standard">
                    <InputLabel>First Name</InputLabel>
                    <Input
                      type="text"
                      required
                      name="fname"
                      placeholder="First Name"
                      fullWidth
                      value={formik.values.fname}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      }
                    />

                    <div className="dealerformerror">{formik.errors.fname}</div>
                  </FormControl>
                </Grid>

                <Grid item xs={12} mt={3}>
                  <FormControl variant="standard">
                    <InputLabel>Last Name</InputLabel>
                    <Input
                      type="text"
                      required
                      name="lname"
                      placeholder="Last Name"
                      fullWidth
                      value={formik.values.lname}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      }
                    />

                    <div className="dealerformerror">{formik.errors.lname}</div>
                  </FormControl>
                </Grid>

                <Grid item xs={12} mt={3}>
                  <FormControl variant="standard">
                    <InputLabel>Mobile No</InputLabel>
                    <Input
                      required
                      type="number"
                      name="mobile"
                      placeholder="Mobile No"
                      value={formik.values.mobile}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      fullWidth
                      startAdornment={
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      }
                    />

                    <div className="dealerformerror">
                      {formik.errors.mobile}
                    </div>
                  </FormControl>
                </Grid>
                <Grid item xs={12} mt={3}>
                  <FormControl variant="standard">
                    <InputLabel>Email</InputLabel>
                    <Input
                      type="email"
                      required
                      name="email"
                      placeholder="Eamil"
                      fullWidth
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      }
                    />

                    <div className="dealerformerror">{formik.errors.email}</div>
                  </FormControl>
                </Grid>

                <Grid item xs={12} mt={3}>
                  <FormControl variant="standard">
                    <InputLabel>Password</InputLabel>
                    <Input
                      type="password"
                      required
                      name="password"
                      placeholder="Password"
                      fullWidth
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      }
                    />

                    <div className="dealerformerror">{formik.errors.password}</div>
                  </FormControl>
                </Grid>

                <Grid item xs={12} mt={3}>
                  <FormControl variant="standard">
                    <InputLabel>Confirm Password</InputLabel>
                    <Input
                      type="password"
                      required
                      name="cpassword"
                      placeholder="Confirm Password"
                      fullWidth
                      value={formik.values.cpassword}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      }
                    />
                  
                  <div className='dealerformerror'>
                      {formik.errors.cpassword}
                      </div>
                  </FormControl>
                </Grid>

                <Grid item xs={12} mt={5}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      borderRadius: 10,
                      background:
                        "linear-gradient(to bottom right, #1CC3E2, #CB2BF9)",
                      width: 240,
                      "&:hover": {
                        background:
                          "linear-gradient(to bottom right, #CB2BF9, #1CC3E2)",
                      },
                    }}
                    type="submit"
                  >
                    Create
                  </Button>
                </Grid>

                <Grid item xs={12} mt={2}>
                  <Typography variant="body2">
                    <a onClick={() => navigate("/login")}>I Have an Account</a>
                  </Typography>
                </Grid>
              </form>
            </Card>
          </Grid>
        </center>
      </Container>

      {/* Snackbar */}

      <Snackbar
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Dealer Create Successfully..! Please Login
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Signin