import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './pages/delerlogin/Login';
import Userdetails from './pages/form/applyloan/Userdetails';
import Signin from './pages/delerlogin/Signin';
import Carpricevalidataion from './pages/form/applyloan/carprice/Carpricevalidataion';
import Headerbar from './components/navbar/Headerbar';
import Preloader from './components/preloader/Preloader';
import { useEffect, useState } from 'react';
import ForgetPage from './pages/forget_password/ForgetPage';
import EditPass from './pages/forget_password/EditPass';

function App() {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const t = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(t);
    }
  }, []);

  return (
    <div className="App">
      {
        loading ? (

          <Preloader />
        ) : (
          <div>

            <BrowserRouter>
              <Routes>
                {/* <Route path='/maintenance' Component={Undermain} /> */}
                <Route path='/' Component={Headerbar} />
                <Route path='/form' Component={Userdetails} />
                <Route path='/login' Component={Login} />
                <Route path='/login/forgetpage' Component={ForgetPage} />
                <Route path='/editpass' Component={EditPass} />
                <Route path='/signup' Component={Signin} />
                <Route path='/validation' Component={Carpricevalidataion} />
              </Routes>
            </BrowserRouter>
          </div>
        )
      }
    </div>
  )
}

export default App;
