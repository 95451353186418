import React from 'react'
import './preloader.css'

const Preloader = () => {
    return (
        <div className='bd'>
            <ul className='pul'>
                <li className='pli'></li>
                <li className='pli'></li>
                <li className='pli'></li>
                <li className='pli'></li>
                <li className='pli'></li>
                <li className='pli'></li>
                <li className='pli'></li>
            </ul>
        </div>
    )
}

export default Preloader