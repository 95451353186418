import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  colors,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Userpage from "./userpage/Userpage";
import Vehicle from "./vechiledetails/Vehicle";
import Cibilprofile from "./cibile/Cibilprofile";
import { Box, Card, Container, Grid } from "@mui/material";
import Result from "./loanresult/Result";
import VehicleValidation from "./vechiledetails/VehicleValidation";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { GroupAdd, Settings, VideoLabel } from "@mui/icons-material";
import Check from '@mui/icons-material/Check';

function getSteps() {
  return [
    "User information",
    "Vehicle Information",
    "Cibile Profile",
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return (
        <>
          <Userpage />
        </>
      );

    case 1:
      return (
        <>
          <VehicleValidation />
        </>
      );
    case 2:
      return (
        <>
          <Cibilprofile />
        </>
      );
    default:
      return "unknown step";
  }
}

const Userdetails = () => {

  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 1 || step === 2;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    const icons = {
      1: <Settings />,
      2: <GroupAdd />,
      3: <VideoLabel />,
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  return (
    <div style={{ height: '100vh', overflow: 'auto', background: 'linear-gradient(to right bottom, #F6E8C8,#F0857B, #9B66F3, #7BA4F1)' }}>

      <Container maxWidth='md' sx={{color:'#FF6000'}}>
        <Card sx={{ mt: 2, mb: 2, borderRadius: 10, p:1, boxShadow: 20,bgcolor:'#FFA559'}}>
          <Typography variant="h4" style={{ fontWeight: 'bold',color:'#FF6000' }}>Check Your Cibil Score</Typography>
          <div>

            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
              {steps.map((step, index) => {
                const labelProps = {};
                const stepProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <>
                    </>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step  {...stepProps} key={index}>
                    <StepLabel  StepIconComponent={ColorlibStepIcon}>{step}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === steps.length ? (
              <Result />
            ) : (
              <>
                <form style={{ marginBottom: '20px' }}>{getStepContent(activeStep)}</form>
                <Button

                  disabled={activeStep === 0}
                  onClick={handleBack}
                  color="error"
                >
                  back
                </Button>

                <Button
                  variant="contained"
                  onClick={handleNext}
                  style={{ backgroundColor: '#FF5000', color: 'white' }}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </>
            )}
          </div>
        </Card>


      </Container>

    </div>

  );
};

export default Userdetails;