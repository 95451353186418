import React from "react";
import "./hero.css";
import carimg from "../../asserts/car.png";
import valuation from "../../asserts/hero/carvaluation.jpg";
import loan from "../../asserts/hero/carloan.jpg";
import Header from "../../components/navbar/Header";
import { useNavigate } from "react-router-dom";

export default function Hero() {

  const navigate = useNavigate()
  const handleApply = () =>{
    navigate('/form')
  }

  return (
 <div>
    
    <div className="container"  style={{marginTop:"-50px"}}>
      <div className=" row homesection d-flex justify-content">
        <div className=" contentleft col-12 col-md-6">
          <div className="contenttext fw-bold text-start">
            <p>
              <i class="fa fa-check-circle" aria-hidden="true" />
              Get Your Dream New Car Starting From 7.50%
            </p>
            <p>
              <i class="fa fa-check-circle" aria-hidden="true" />
              Starting From Lowest Used Car Rate
            </p>
            <p>
              <i class="fa fa-check-circle" aria-hidden="true" />
              Personal Loan with Great Interest
            </p>
          </div>
          <div className="contentcard ">
           <div className="row">
           <div className="card1  shadow text-center col-md-3">
              <div className="image">
                <img src={loan} className="img-fluid" alt="loanimg" />
              </div>
                    <button className="btn btn-success buy-now"onClick={()=>navigate('/validation')}>
                Used Car Validation
                </button>
           
            </div>
            {/* card valuation */}
            <div className="card1 shadow text-center col-md-3 loancard">
              <div className="image">
                <img src={valuation} className="img-fluid" alt="valuation img"/>
              </div>

              <div className="about-product text-center">
              

                <button className="btn btn-success buy-now" onClick={()=>{
                  handleApply()
                }}>
                 Apply Loan
                </button>
              </div>
            </div>
           </div>
          </div>
        </div>
        {/* home image */}
        <div className="contentright col-12 col-md-6">
         <img src={carimg} className="img-fluid carimg" alt="carimg"/>
        </div>
      </div>
    </div>
 </div>
  );
}
