import { Button, Card, Divider, Grid, Paper, Table, TableCell, TableRow, Typography, TableContainer, CardContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import speed1 from '../../../../asserts/formsimg/speed1.png'
import speed2 from '../../../../asserts/formsimg/speed2.png'
import { useNavigate } from 'react-router-dom'
import ReactConfetti from 'react-confetti'

const Result = () => {
  const navigate = useNavigate()

  const [windowDimention, setDimention] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });


  const detectSize = () => {
    setDimention({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimention]);


  return (
    <div>
      <ReactConfetti
        width={windowDimention.width}
        height={windowDimention.height}
        tweenDuration={1000}
      />
      <Grid item container xs={12} alignItems='center' justifyContent='center'>
        <Card sx={{ bgcolor: '#D4EDDA', p: 1, boxShadow: 5 }}>
          <Grid item xs={12}>
            <Typography variant='h6'>LOAN ELIGIBILITY FORECAST - <br /> VEHICLE RESULT</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 1 }}>
              2021 - MARUTI SUZUKI - CELERIO - VXI - COIMBATORE
              VEHICLE PRICE :    ₹ 2,00,000
            </Paper>
            <Grid item xs={12}>
             <Card>
              <CardContent>
                <Grid container xs={12}>
                  <Grid item md={4} xs={6} sx={{borderRight:1}}>
                  <Typography align='center'>Loan Value <br />₹ 1,20,000</Typography>
                  </Grid>
                  
                  <Grid item md={4} xs={6} sx={{borderRight:1}}>
                  <Typography align='center'>EMI <br /> ₹ 11,767 <br />Tenure <br />12 Months</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography align='center'>DOWNPAYMENT <br />  70,000 <br />ROI <br />15.55 %</Typography>
                  </Grid>
                </Grid>
              </CardContent>
             </Card>
               
              
            </Grid>


            <Grid container item xs={12} spacing={2} mt={2} display='flex' alignItems='center' justifyContent='center'>
              <Grid item xs={12} md={6}>
                <img src={speed1} alt="" height="200px" />
              </Grid>
              <Grid item xs={12} md={6}>
                <img src={speed2} alt="" height="200px" />
              </Grid>
            </Grid>


            <Grid item xs={12} mb={1}>
              <Button variant='contained' sx={{
                backgroundColor: '#FFA800',
                '&:hover': {
                  background: '#F2562A'
                }
              }} onClick={() => { navigate('/') }}>Home</Button>
            </Grid>

          </Grid>
        </Card>
      </Grid>
    </div>
  )
}

export default Result