import {
  AccountCircle,
  Facebook,
  Google,
  Lock,
  Twitter,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";

const Login = () => {
  const navigate = useNavigate();

  const initalvalues = {
    email: "",
    password: "",
    mobile: "",
  };
  const formik = useFormik({
    initialValues: initalvalues,

    validationSchema: yup.object({
      email: yup.string().required("Enter your email or phonenumber"),
      password: yup
        .string()
        .required("Please enter password")
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
    }),

    onSubmit: (dealerData) => {
      axios.defaults.withCredentials = true;
      axios
        .post("http://localhost:5000/login", dealerData)
        .then((res) => {
          if (res.data.Status === "Success") {
            setName(res.data.name);
            setOpen(true);
            setTimeout(() => {
              navigate("/");
            }, 3000);
          } else {
            alert(res.data.Message);
          }
        })
        .catch((err) => console.log("Error in Front Login Page", err));
    },
  });

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        height: "100vh",
        overflow: "hidden",
        background: "linear-gradient(to right bottom, #32B1E4, #CC14E2)",
      }}
    >
      <Container maxWidth="lg">
        <center>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            mt={5}
          >
            <Box
              sx={{
                bgcolor: "white",
                p: 5,
                borderRadius: 10,
                height: 600,
                width: 400,
                boxShadow: 5,
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  fontFamily="sans-serif"
                >
                  Dealer Login
                </Typography>
                <Divider variant="middle" />
              </Grid>

              <form onSubmit={formik.handleSubmit}>
                <Grid item xs={12} mt={5}>
                  <FormControl variant="standard">
                    <InputLabel>Dealer Eamil or Mobile No</InputLabel>
                    <Input
                      name={"email" || "mobile"}
                      placeholder="Email or Mobile No"
                      fullWidth
                      value={formik.values.email || formik.values.mobile}
                      onChange={formik.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      }
                    />
                    <div className="dealerformerror">{formik.errors.email}</div>
                  </FormControl>
                </Grid>

                <Grid item xs={12} mt={5}>
                  <FormControl variant="standard">
                    <InputLabel>Password</InputLabel>
                    <Input
                      name="password"
                      placeholder="Password"
                      type="password"
                      fullWidth
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      }
                    />
                    <div className="dealerformerror">
                      {formik.errors.password}
                    </div>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" textAlign="end" sx={{ mr: 5 }}>
                    <a
                      onClick={() => navigate("forgetpage")}
                      style={{ textDecoration: "none" }}
                    >
                      Forget Password
                    </a>
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={5}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      borderRadius: 10,
                      background:
                        "linear-gradient(to bottom right, #1CC3E2, #CB2BF9)",
                      width: 240,
                      "&:hover": {
                        background:
                          "linear-gradient(to bottom right, #CB2BF9, #1CC3E2)",
                      },
                    }}
                  >
                    Sign In
                  </Button>
                </Grid>
              </form>

              <Grid justifyContent="center" alignItems="center" xs={12} mt={6}>
                <IconButton
                  sx={{
                    bgcolor: "#3B5998",
                    color: "white",
                    ml: 2,
                    "&:hover": {
                      backgroundColor: "Brown",
                    },
                  }}
                >
                  <Twitter />
                </IconButton>
                <IconButton
                  sx={{
                    bgcolor: "#1DA1F2",
                    color: "white",
                    ml: 2,
                    "&:hover": {
                      backgroundColor: "Brown",
                    },
                  }}
                >
                  <Facebook />
                </IconButton>
                <IconButton
                  sx={{
                    bgcolor: "#EA4335",
                    color: "white",
                    ml: 2,
                    "&:hover": {
                      backgroundColor: "Brown",
                    },
                  }}
                >
                  <Google />
                </IconButton>
              </Grid>

              <Grid item xs={12} mt={3}>
                <Typography variant="subtitle1">Or Sign Up Using</Typography>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography variant="subtitle1">
                  <Button variant="text" onClick={() => navigate("/signup")}>
                    SIGN UP
                  </Button>
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </center>
      </Container>

      <Snackbar
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Please Welcome {name}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
