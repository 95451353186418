import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Fade } from 'react-reveal'

const Scrollbtn = () => {

  const [btt, setBtt] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBtt(true);
      } else {
        setBtt(false);
      }
    });
  }, []);

  const scrollup = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <Box>
        {btt && (
     
            <Button
              sx={{
                position: "fixed",
                zIndex:'9',
                bottom: "25px",
                right: "25px",
                height: "50px",
                borderRadius: "100px",
                fontsize: "80px",
                bgcolor: '#1743E3',
                color: 'white',
                "&:hover": {
                  color: '#1743E3'
                }
              }}
              onClick={scrollup}
            >
              <FontAwesomeIcon icon={faArrowUp} fontSize={20} bounce />
            </Button>
        )}
      </Box>
    </div>
  )
}

export default Scrollbtn