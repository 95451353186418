import { Box, ButtonGroup, Card, Radio, RadioGroup } from '@mui/material';
import { Autocomplete, Button, CardContent, Container, FormControl, FormControlLabel, Grid, Modal, TextField, Typography } from '@mui/material'
import modelcar from '../../../../asserts/formsimg/modelcal.png'
import result from '../../../../asserts/formsimg/result.png'
import React, { useState, useEffect } from 'react'
import { Bounce, Fade, Roll, Zoom } from 'react-reveal';
import ReactConfetti from "react-confetti";
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';


const VehicleValidation = () => {

    const [open, setOpen] = useState(false);
    const [btn, setBtn] = useState(false);


    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            year: '',
            city: '',

        }
    })

    const handleOpen = () => {
        setOpen(true);
        setBtn(!btn);
    };

    const dis = require('../../../../dummydatas/carpriceform.json')


    const handleClose = () => {
        navigate('/form')
        setOpen(false)
        setBtn(false)
    };

    const handleCloseModel = () => {
        setOpen(false)
        setBtn(false)
    }

    const [windowDimention, setDimention] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });


    const detectSize = () => {
        setDimention({ width: window.innerWidth, height: window.innerHeight });
    };

    useEffect(() => {
        window.addEventListener("resize", detectSize);
        return () => {
            window.removeEventListener("resize", detectSize);
        };
    }, [windowDimention]);

    return (


        <Container maxWidth='lg' sx={{ mt: 5, borderRadius: 10}} >

            <Grid container xs={12} display='flex' alignItems='center' justifyContent='center'>

                   
                        <Fade top>

                            <div style={{ margin: 5, backgroundColor: '#FFE6C7', padding: 15, borderRadius: 10, boxShadow:5, }}>

                                {/* <Grid item xs={12} mt={2}>
                                            <Typography variant='h5' fontWeight="bold" alignItems='center' borderRadius={5} display='flex' justifyContent='center' fontFamily='' boxShadow={5} bgcolor="#EDE4FF" height={40}>Used Car Price Calculator</Typography>
                                        </Grid> */}

                                <Grid xs={12} container columnSpacing={1} rowSpacing={1}>

                                    <Grid item xs={12} md={6} mt={1}>
                                        <Autocomplete
                                            size='small'
                                            freeSolo
                                            options={dis.Years.map((option) => option)}
                                            renderInput={(params) => <TextField variant='outlined' size='small'  {...params} required label="Year" />}
                                        />

                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            size='small'
                                            freeSolo
                                            options={dis.disticts.map((option) => option)}
                                            renderInput={(params) => <TextField margin='dense' {...params} label="City" required variant='outlined' />}
                                        />
                                    </Grid>
                                </Grid>

                                {/* Cars Varient, Make, Type */}

                                <Grid item xs={12} mt={1} container columnSpacing={1} rowSpacing={1}>

                                    <Grid item xs={12}>
                                        <Typography fontWeight='bold' textAlign='start' sx={{color:'#FF6000'}}>Select Your Model / Brand</Typography>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                sx={{ mt: 1 }}
                                                size='small'
                                                freeSolo
                                                options={dis.make.map((option) => option)}
                                                renderInput={(params) => <TextField variant='outlined' size='small' {...params} required label="Make" />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                sx={{ mt: 1 }}
                                                size='small'
                                                freeSolo
                                                options={dis.cars.map((option) => option.model)}
                                                renderInput={(params) => <TextField variant='outlined' size='small' {...params} required label="Model" />}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                sx={{ mt: 1 }}
                                                size='small'
                                                freeSolo
                                                options={dis.varient.map((option) => option)}
                                                renderInput={(params) => <TextField variant='outlined' size='small' {...params} required label="Varient" />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} mt={1}>
                                            <TextField
                                                label='Killometer Driven'
                                                variant='outlined'
                                                size='small'
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>



                                <Grid item xs={12} mt={2}>
                                    <Grid item xs={12}>
                                        <Typography fontWeight='bold' textAlign='start' sx={{color:'#FF6000'}}>Owners : </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                            >
                                                <FormControlLabel
                                                    value="first"
                                                    control={<Radio />} label="1st" />

                                                <FormControlLabel value="second"
                                                    control={<Radio />} label="2nd" />

                                                <FormControlLabel value="other"
                                                    control={<Radio />} label="3rd" />

                                                <FormControlLabel
                                                    value='4th'
                                                    control={<Radio />}
                                                    label="4th Or More"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* <Grid item xs={12} mt={2}>
                                    <Button
                                        onClick={handleOpen}
                                        variant='contained'
                                        sx={{
                                            "&:hover": {
                                                background: 'linear-gradient(to right top, #1743E3, #09D4DF)'
                                            }
                                        }
                                        }

                                        fullWidth
                                    >
                                        Check Value
                                    </Button>
                                </Grid> */}

                            </div>
                        </Fade>
                
            </Grid>


            <div>
                {btn && (
                    <div>
                        <ReactConfetti
                            width={windowDimention.width}
                            height={windowDimention.height}
                            tweenDuration={1000}
                        />
                        <Modal
                            open={open}
                            onClose={handleClose}
                            sx={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            <Zoom>
                                <Card style={{ borderRadius: 20, backgroundColor: '#75C2F6', }}>

                                    <CardContent>
                                        <Box style={{ backgroundColor: '#1D5D9B', padding: 8, paddingLeft: 10, color: 'white', borderRadius: 5 }}>
                                            <Grid item container xs={12}>
                                                <Typography variant="h6" component="h2">
                                                    Hyundai Creta [2020-2023] SX (O) 1.5 Diesel [2020-2022]
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" component="h2">
                                                    Diesel | 2021 | Mumbai
                                                </Typography>
                                            </Grid>
                                        </Box>
                                        <Grid container item xs={4} md={12} display='flex' alignItems='center' justifyItems='center'>
                                            <Zoom>
                                                <img src={modelcar} height={200} width={300} style={{ marginLeft: "25%" }} />
                                            </Zoom>
                                        </Grid>

                                        <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
                                            <Box>
                                                <Grid item xs={2} md={12} display='flex'>
                                                    <Fade left>
                                                        <Typography variant='body1'>Good Condition <br /> 3.35Lakh</Typography>
                                                    </Fade>
                                                    <Bounce top>
                                                        <img src={result} alt="" height={100} width={100} />
                                                    </Bounce>
                                                    <Fade right>
                                                        <Typography variant='body1'>Fair Condition <br /> 3.71Lakh</Typography>
                                                    </Fade>
                                                </Grid>

                                            </Box>
                                        </Grid>
                                        <Grid container ml={2} mt={1}>
                                            <Button variant="contained" onClick={handleClose} color="success">
                                                Apply Loan
                                            </Button>
                                            <Button sx={{ ml: 2 }} variant="contained" onClick={handleCloseModel} color="warning">
                                                Close
                                            </Button>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Zoom>
                        </Modal>
                    </div>
                )}
            </div>
        </Container>
    )
}

export default VehicleValidation