import {
  Box,
  ButtonGroup,
  Card,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Autocomplete,
  Button,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import modelcar from "../../../../asserts/formsimg/modelcal.png";
import result from "../../../../asserts/formsimg/result.png";
import rosecar from "../../../../asserts/formsimg/rosecar.png";
import React, { useState, useEffect } from "react";
import { Bounce, Fade, Roll, Zoom } from "react-reveal";
import ReactConfetti from "react-confetti";
import { useNavigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { Form } from "react-bootstrap";

const Carpricevalidataion = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };


  // const [ownerAlignment, setOwnerAlignment] = useState('1st')
  const [open, setOpen] = useState(false);
  const [btn, setBtn] = useState(false);


  // const handleDropdowns = (e) => {
  //   const { name, value } = e.target;
  //   setDropdown({
  //     ...dropdown,
  //     [name]: value,
  //   });
  // };

  // const navigate = useNavigate();



  const handleOpen = () => {
    // formik.handleSubmit();
    // setOpen(true);
    // setBtn(!btn);
  };

  const dis = require("../../../../dummydatas/carpriceform.json");


  const handleClose = () => {
    // navigate("/form");
    setOpen(false);
    setBtn(false);
  };

  const handleCloseModel = () => {
    setOpen(false);
    setBtn(false);
  };

  const [windowDimention, setDimention] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const detectSize = () => {
    setDimention({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimention]);

  return (

    <Formik 
    initialValues={{
      year:"",
      city:"",
      model:"",
      make:"",
      varient:"",
      kilometer:"",
      owners:""
    }}
    
    validationSchema={yup.object({
     
    })}> 
    <div
      style={{
        height: "100vh",
        overflow: "hidden",
        background: "linear-gradient(to right bottom, #1743E3, #82ffa1)",
      }}
    >
      <div>
        <Container
          maxWidth="lg"
          component="main"
          sx={{
            mt: 5,
            pb: 5,
            color: "#CB29F8",
            borderRadius: 10,
            display: "flex",
            bgcolor: "#106693",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container item xs={12} spacing={6}>
            <Grid
              item
              xs={6}
              sx={{ display: { xs: "none", md: "flex" } }}
              spacing={2}
              mt={3}
            >
              <Zoom>
                <img src={rosecar} style={{ boxShadow: 10 }} />
              </Zoom>
            </Grid>
            <Grid item xs={12} md={6} mt={5}>
              <form>
                <Fade top>
                  <div
                    style={{
                      margin: 5,
                      backgroundColor: "#D4E2D4",
                      padding: 15,
                      borderRadius: 10,
                    }}
                  >
                    <Grid item xs={12} mt={2}>
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        alignItems="center"
                        borderRadius={5}
                        display="flex"
                        justifyContent="center"
                        fontFamily=""
                        boxShadow={5}
                        bgcolor="#EDE4FF"
                        height={40}
                      >
                        Used Car Price Calculator
                      </Typography>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} mt={2}>
                        <Autocomplete
                          size="small"
                          freeSolo
                          options={dis.Years.map((option) => option)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Year"      
                              variant="outlined"
                             autoComplete="off"
                            />
                          )}
                        />
                      
                      </Grid>

                      <Grid item xs={12} mt={1}>
                        <Autocomplete
                          size="small"
                          freeSolo
                          options={dis.disticts.map((option) => option)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select City"
                              autoComplete="off"    variant="outlined"
                            />
                          )}
                        />
                      
                      </Grid>
                    </Grid>

                    {/* Cars Varient, Make, Type */}

                    <Grid
                      item
                      xs={12}
                      mt={1}
                      container
                      columnSpacing={1}
                      rowSpacing={1}
                    >
                      <Grid item xs={12}>
                        <Typography fontWeight="bold" textAlign="start">
                          Select Your Model / Brand
                        </Typography>
                      </Grid>
                      <Grid container xs={12} spacing={1}>
                        <Grid item xs={6}>
                          <Autocomplete
                            sx={{ mt: 1 }}
                            size="small"
                            freeSolo
                            options={dis.cars.map((option) => option.model)}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                size="small"
                                {...params}             
                                label="Model"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Autocomplete
                            sx={{ mt: 1 }}
                            size="small"
                            freeSolo
                            options={dis.make.map((option) => option)}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                size="small"
                                {...params}
                             
                                label="Make"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            sx={{ mt: 1 }}
                            size="small"
                            freeSolo
                            options={dis.varient.map((option) => option)}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                size="small"
                                {...params}
                             
                                label="Varient"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <TextField
                            label="Killometer Driven"
                            variant="outlined"
                            size="small"
                          
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} mt={2}>
                      <Grid item xs={12}>
                        <Typography fontWeight="bold" textAlign="start">
                          Owners :{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup row>
                            <FormControlLabel
                              value="first"
                              control={<Radio />}
                              label="1st"
                            />

                            <FormControlLabel
                              value="second"
                              control={<Radio />}
                              label="2nd"
                            />

                            <FormControlLabel
                              value="other"
                              control={<Radio />}
                              label="3rd"
                            />

                            <FormControlLabel
                              value="4th"
                              control={<Radio />}
                              label="4th Or More"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} mt={2}>
                      <Button
                        onClick={handleOpen}
                        variant="contained"
                        sx={{
                          "&:hover": {
                            background:
                              "linear-gradient(to right top, #1743E3, #09D4DF)",
                          },
                        }}
                        fullWidth
                      >
                        Check Value
                      </Button>
                    </Grid>
                  </div>
                </Fade>
              </form>
            </Grid>
          </Grid>
        </Container>
        <div>
          {btn && (
            <div>
              <ReactConfetti
                width={windowDimention.width}
                height={windowDimention.height}
                tweenDuration={1000}
              />
              <Modal
                open={open}
                onClose={handleClose}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Zoom>
                  <Card
                    style={{ borderRadius: 20, backgroundColor: "#75C2F6" }}
                  >
                    <CardContent>
                      <Box
                        style={{
                          backgroundColor: "#1D5D9B",
                          padding: 8,
                          paddingLeft: 10,
                          color: "white",
                          borderRadius: 5,
                        }}
                      >
                        <Grid item container xs={12}>
                          <Typography variant="h6" component="h2">
                            Hyundai Creta [2020-2023] SX (O) 1.5 Diesel
                            [2020-2022]
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" component="h2">
                            Diesel | 2021 | Mumbai
                          </Typography>
                        </Grid>
                      </Box>
                      <Grid
                        container
                        item
                        xs={4}
                        md={12}
                        display="flex"
                        alignItems="center"
                        justifyItems="center"
                      >
                        <Zoom>
                          <img
                            src={modelcar}
                            height={200}
                            width={300}
                            style={{ marginLeft: "25%" }}
                          />
                        </Zoom>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box>
                          <Grid item xs={2} md={12} display="flex">
                            <Fade left>
                              <Typography variant="body1">
                                Good Condition <br /> 3.35Lakh
                              </Typography>
                            </Fade>
                            <Bounce top>
                              <img
                                src={result}
                                alt=""
                                height={100}
                                width={100}
                              />
                            </Bounce>
                            <Fade right>
                              <Typography variant="body1">
                                Fair Condition <br /> 3.71Lakh
                              </Typography>
                            </Fade>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid container ml={2} mt={1}>
                        <Button
                          variant="contained"
                          onClick={handleClose}
                          color="success"
                        >
                          Apply Loan
                        </Button>
                        <Button
                          sx={{ ml: 2 }}
                          variant="contained"
                          onClick={handleCloseModel}
                          color="warning"
                        >
                          Close
                        </Button>
                      </Grid>
                    </CardContent>
                  </Card>
                </Zoom>
              </Modal>
            </div>
          )}
        </div>
      </div>
    </div>
    </Formik>
  );
};

export default Carpricevalidataion;
