import { Box, Button, Card, Container, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import {Fade, Flip} from 'react-reveal';
import "./contactform.css"

const Contactform = () => {
  return (
    <>
      <div className='contact-session'>
        <Container>
          <Grid sx={{ flexGrow: 1 }} container xs={12} spacing={2}>
          
            <Fade right>
              <Typography variant="h4" textAlign={"start"} pl={1}>
                Get In Touch
              </Typography>
              <Typography varient="body1" textAlign={"justify"}  pl={1}>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis
                quae nostrum explicabo repellendus nobis quia. Dicta, odio ipsam.
                Obcaecati soluta eius excepturi officiis iure incidunt et debitis,
                hic ullam saepe?
              </Typography>
            </Fade>
     

            <Stack mt={4} direction={'row'} spacing={3} alignItems='center' justifyContent='center'>

              <Grid xs={6} display={{ md: "flex", xs: "none" }}>
                <Fade left>
                <img
                  style={{ height: "500px", width: "600px" }}
                  src="https://www.konnectplugins.com/loanica/images/contact.svg"
                />
                </Fade>
              </Grid>
              
              <Grid xs={12} >
                <Flip right>
                <Box
                  sx={{
                    borderRadius: 5,
                    border: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ bgcolor: 'blue', color: 'white', width: '100%', borderRadius: '20px 20px 0px 0px', pt: 1 }}>
                    <Typography variant="h4" fontWeight='bold'>Send Message</Typography>
                  </Box>

                  <Box component="form" noValidate p={4}>

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="name"
                      label="Name"
                      type="name"
                      id="name"
                      autoComplete="off"
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="off"
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      id="outlined-textarea"
                      label="Message"
                      rows={4}
                      multiline
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 1, float: 'left', bgcolor:'#1743E3','&:hover':{bgcolor:'white', color:'#1743E3'} }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
                </Flip>
              </Grid>
            </Stack>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default Contactform