import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import About from "../../pages/About/About";
import Fotter from "../Footer/Fotter";
import Partners from "../../pages/channel_partners/Partners";
import Timechat from "../timeline/Timechat";
import Contactform from "../../pages/ContactForm/Contactform";
import Hero from "../../pages/hero/Hero";
import Services from "../../pages/services/Services";
import Scrollbtn from "../utilitis/Scrollbtn";
import "./headerbar.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EmiCalculator from "../emicalculator/EmiCalculator";
import Eligibility from "../eligibilty/Eligiblity";

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];

function Headerbar(props) {
  const { window } = props;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [auth, setAuth] = React.useState(false);
  const [name, setName] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <>
      <Box
        onClick={handleDrawerToggle}
        sx={{ textAlign: "center", bgcolor: "#0012E0", height: "100vh" }}
      >
        <Typography variant="h6" sx={{ my: 2, color: "#fff" }}>
          AgalyaFin
        </Typography>

        <Divider />
        <List sx={{ color: "#fff" }}>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText sx={{ borderBottom: 2, borderColor: "#fff" }}>
                <Button
                  href="home"
                  fullWidth
                  className="hbtn"
                  sx={{ color: "white", fontWeight: 30 }}
                >
                  Home
                </Button>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText sx={{ borderBottom: 2, borderColor: "#fff" }}>
                <Button
                  href="about"
                  fullWidth
                  className="hbtn"
                  sx={{ color: "white" }}
                >
                  About
                </Button>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText sx={{ borderBottom: 2, borderColor: "#fff" }}>
                <Button
                  href="services"
                  fullWidth
                  className="hbtn"
                  sx={{ color: "white" }}
                >
                  Services
                </Button>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText sx={{ borderBottom: 2, borderColor: "#fff" }}>
                <Button
                  href="contact"
                  fullWidth
                  className="hbtn"
                  sx={{ color: "white" }}
                >
                  Contact
                </Button>
              </ListItemText>
            </ListItemButton>
          </ListItem>

          <div>
            {auth ? (
              <div>
                <h6>Dealer Name : {name}</h6>
                <ListItem>
                  <ListItemButton
                    className="item-button"
                    sx={{
                      color: "blue",
                      bgcolor: "white",
                      textAlign: "center",
                    }}
                  >
                    <ListItemText>Dealer Logout</ListItemText>
                  </ListItemButton>
                </ListItem>
              </div>
            ) : (
              <div>
                <h6>{message}</h6>
                <ListItem>
                  <ListItemButton
                    onClick={() => navigate("/login")}
                    className="item-button"
                    sx={{
                      color: "blue",
                      bgcolor: "white",
                      textAlign: "center",
                    }}
                  >
                    <ListItemText>Dealer Login</ListItemText>
                  </ListItemButton>
                </ListItem>
              </div>
            )}
          </div>
        </List>
      </Box>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <CssBaseline />
      <AppBar component="nav" sx={{ bgcolor: "#3C14D0" }}>
        <Toolbar sx={{ height: 90 }}>
            <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
           <div className="d-flex justify-content-between">
            <div>
            AgalyaFin
            </div>
            <div style={{paddingLeft:"200px"}}>
            <MenuIcon />
            </div>
            </div> 
          
     
            
          
         
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}
          >
            AgalyaFin
          </Typography>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: { xs: "none", sm: "flex" },
            }}
          >
            <div style={{ marginRight: "20px" }}>
              <Button
                href="#home"
                className="hbtn"
                sx={{
                  color: "#fff",
                  fontSize: 18,
                  fontWeight: "bold",
                  fontStyle: "oblique",
                }}
              >
                Home
              </Button>
              <Button
                href="#about"
                className="hbtn"
                sx={{
                  color: "#fff",
                  fontSize: 18,
                  fontWeight: "bold",
                  fontStyle: "oblique",
                }}
              >
                About
              </Button>
              <Button
                href="#services"
                className="hbtn"
                sx={{
                  color: "#fff",
                  fontSize: 18,
                  fontWeight: "bold",
                  fontStyle: "oblique",
                }}
              >
                Service
              </Button>
              <Button
                href="#contact"
                className="hbtn"
                sx={{
                  color: "#fff",
                  fontSize: 18,
                  fontWeight: "bold",
                  fontStyle: "oblique",
                }}
              >
                Contact
              </Button>
            </div>

            {auth ? (
              <div>
                <h6>{name}</h6>
                <Button
                  className="item-button"
                  sx={{
                    color: "blue",
                    bgcolor: "white",
                    fontSize: 18,
                    fontWeight: "bold",
                    fontStyle: "oblique",
                  }}
                >
                  Dealer Logout
                </Button>
              </div>
            ) : (
              <div>
                <h6>{message}</h6>
                <Button
                  className="item-button"
                  onClick={() => navigate("/login")}
                  sx={{
                    color: "blue",
                    bgcolor: "white",
                    fontSize: 18,
                    fontWeight: "bold",
                    fontStyle: "oblique",
                  }}
                >
                  Dealer Login
                </Button>
              </div>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main">
        <Toolbar />
        <div id="home">
          <Scrollbtn />
          <Hero />
          {/* < EmiCalculator /> */}
       
        </div>
       <div className="">
       <Eligibility />
       </div>
        {/* <div id="about">
          <About />
        </div> */}
        <div id="services">
          {/* <Services /> */}
          <Partners />
        </div>
        <div id="contact">
          <Contactform />
        </div>
        <div>
          <Fotter />
        </div>
      </Box>
    </Box>
  );
}

export default Headerbar;
