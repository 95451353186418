import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Fotter = () => {
  return (
    <div>
      {/* Remove the container if you want to extend the Footer to full width. */}
      <div className="mt-3">
        {/* Footer */}
        <footer
          className="text-center text-lg-start text-white"
          style={{ backgroundColor: "#1c2331" }}
        >
          {/* Section: Social media */}
          <section
            className="d-flex justify-content-between p-4"
            style={{ backgroundColor: "#6351ce" }}
          >
            {/* Left */}
            <div className="me-5">
              <span>Get connected with us on social networks:</span>
            </div>
            {/* Left */}
            {/* Right */}
            <div>
              <a href className="text-white me-4 ">
              <FontAwesomeIcon icon="fa-brands fa-square-facebook" />
              </a>
              <a href className="text-white me-4">
                <i className="fab fa-twitter" />
              </a>
              <a href className="text-white me-4">
                <i className="fab fa-google" />
              </a>
              <a href className="text-white me-4">
                <i className="fab fa-instagram" />
              </a>
              <a href className="text-white me-4">
                <i className="fab fa-linkedin" />
              </a>
              <a href className="text-white me-4">
                <i className="fab fa-github" />
              </a>
            </div>
            {/* Right */}
          </section>
          {/* Section: Social media */}
          {/* Section: Links  */}
          <section className>
            <div className="container text-center text-md-start mt-5">
              {/* Grid row */}
              <div className="row mt-3">
                {/* Grid column */}
                <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4">
                  {/* Content */}
                  <h6 className="text-uppercase fw-bold">AgalyaFin</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                  />
                  <p>
                    Here you can use rows and columns to organize your footer
                    content. Lorem ipsum dolor sit amet, consectetur adipisicing
                    elit.
                  </p>
                </div>
                {/* Grid column */}
                {/* Grid column */}
                <div className="col-md-4 col-lg-4 col-xl-4 text-center mb-4">
                  {/* Links */}
                  <h6 className="text-uppercase fw-bold">Quick Links</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                  />
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                     Home
                    </a>
                  </p>
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                 About
                    </a>
                  </p>
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                    Service
                    </a>
                  </p>
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                     Contact
                    </a>
                  </p>
                </div>
                {/* Grid column */}
                {/* Grid column */}
                {/* <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                  {/* Links */}
                  {/* <h6 className="text-uppercase fw-bold">Useful links</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                  />
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                      Your Account
                    </a>
                  </p>
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                      Become an Affiliate
                    </a>
                  </p>
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                      Shipping Rates
                    </a>
                  </p>
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                      Help
                    </a>
                  </p>
                </div> */} 
                {/* Grid column */}
                {/* Grid column */}
                <div className="col-md-4 col-lg-4 col-xl-4  mx-auto mb-md-0 mb-4">
                  {/* Links */}
                  <h6 className="text-uppercase fw-bold">Contact</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                  />
                  <p>
                  <i class="fa-solid fa-house"></i>11/17,B Kottanar 3rd Street
                  Dindigul-624001
                  </p>
                  <p>
                    <i className="fas fa-envelope mr-3" /> info@example.com
                  </p>
                  <p>
                    <i className="fas fa-phone mr-3" /> +91 90428-14446  , +91 88708-91622
                  </p>
                 
                </div>
                {/* Grid column */}
              </div>
              {/* Grid row */}
            </div>
          </section>
          {/* Section: Links  */}
          {/* Copyright */}
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2)"}}
          >
            © 2023 Copyright :
            <a className="text-white" href="#" style={{ textDecoration:"none"}}>
            AgalyaFin
            </a> 
            <div>
            <a href="https://virtualtechnology.in/" style={{ textDecoration:"none" }}> Developed by Virtual Technologies</a>
            </div>
           
          </div>
          {/* Copyright */}
        </footer>
        {/* Footer */}
      </div>
      {/* End of .container */}
    </div>
  );
};

export default Fotter;
