import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert, Input, InputLabel, Snackbar } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';

const Userpage = () => {

    const [otp, setOtp] = useState('')
    const [otpOpen, setOtpOpen] = useState(false)

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    const otpClick = () => {
        setOtpOpen(true)
    }
    const otpClose = () => {
        setOtpOpen(false)
    }

    return (
        <Container sx={{boxShadow:5, borderRadius: 5, mt:5, bgcolor:'#FFE6C7', color: '#CB29F8'  }} component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color:'#FF6000'
                }}
            >
                <Box component="form" noValidate sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant='standard'
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                variant='standard'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                             variant='standard'
                             type='email'
                             inputProps={{inputMode:'email'}}
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant='standard'
                                type='number'
                                inputProps={{ inputMode: 'numeric'}}
                                name="phone"
                                required
                                fullWidth
                                id="Phone"
                                label="Phone No"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                sx={{ mt: 1 }}
                                variant='contained'
                            >Check</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel sx={{fontWeight:'bold',color:'#FF6000'}}>
                                OTP Verification
                            </InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <MuiOtpInput value={otp} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant='contained' onClick={otpClick} sx={{ mt: 2, mb:2 }}>Check</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    {/* OTP Alert */}
                    <Snackbar anchorOrigin={{
                    horizontal: "center",
                    vertical: "top",
                }} open={otpOpen} autoHideDuration={6000} onClose={otpClose}>
                        <Alert onClose={otpClose} severity="success" sx={{ width: '100%' }}>OTP Verfication Completed Go To Next Page</Alert>
                    </Snackbar>
                </Box>
            </Box>
        </Container>

    )
}

export default Userpage