import { CheckBox } from '@mui/icons-material';
import { Autocomplete, Box, Checkbox, Container, FormControl, FormControlLabel, FormGroup, Grid, Input, InputLabel, MenuItem, Select, TextField, Typography, } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useState } from 'react'
import { Fade } from 'react-reveal';

const Cibilprofile = () => {

    const [profile, setProfile] = useState(null)
    const [dateValue, setDataValue] = useState(null)
    const [residence, setResidence] = useState(null)
    const [own, setOwn] = useState(false)
    const [leased, setLeased] = useState(null)
    const [slot, setSlot] = useState(null)
    const [rent, setRent] = useState(false)
    const [salaried, setSalaried] = useState(false)
    const [self, setSelf] = useState(false)
    const [agri, setAgri] = useState(false)
    // const [open, setOpen] = useState(false);
    // const [value, setValue] = React.useState('1');
    const details = require('../../../../dummydatas/carpriceform.json')

    const handlecheckbox = (option) => {
        setRent(option)

    }
    const handlecheckSlot = (option) => {
        setSlot(option)
    }

    const handleProfile = (e) => {
        console.log(e.target.value)
        setProfile(e.target.value)
    }

    const handleResidence = (e) => {
        setResidence(e.target.value)
    }

    return (

        <div>
            <Container maxWidth='sm' sx={{ borderRadius: 5, boxShadow: 5, mt: 5, bgcolor: '#FFE6C7' }}>
                <Box>
                    <Grid item sx={{ maxWidth: '100%', overflow: 'hidden', pb: 3, }}>

                            <Typography textAlign='start' variant='subtitle1' fontWeight='bold' sx={{ color: '#FF6000' }} mt={2}>Select Your Data Of Birth</Typography>
                        <Grid container xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker
                                    sx={{ mt: 1, height:40}}
                                    label='Data Of Birth'
                                    value={dateValue}
                                    onChange={newValue => setDataValue(newValue)}
                                />
                            </LocalizationProvider>
                        </Grid>


                        <Grid container xs={12} mt={3}>
                        <Typography sx={{ color: '#FF6000' }} textAlign='start' variant='subtitle1' fontWeight='bold'>Select Customer Profile</Typography>
                            <FormControl fullWidth margin='dense'>
                                <InputLabel>Select Profile Type</InputLabel>
                                <Select
                                    value={profile}
                                    sx={{mt:1}}
                                    size='small'
                                    margin='dense'
                                    label="Select Profile Type"
                                    onChange={(e) => handleProfile(e)}>
                                    <MenuItem value='salaried'>Salaried</MenuItem>
                                    <MenuItem value='selfemp'>Self Employeed</MenuItem>
                                    <MenuItem value='agriculture'>Agriculture</MenuItem>
                                    <MenuItem value='repayment'>Repayment</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid container xs={12} alignItems='center' justifyContent='center'>
                        {
                            profile == "salaried" ? (
                                <Grid container xs={12} columnGap={2} mt={1} alignItems='center' justifyContent='center'>
                                    <TextField type='number' variant='outlined' size='small' label='Monthly Income' margin='dense' />
                                    <TextField type='number' variant='outlined' size='small' label='Existing EMI' margin='dense' />
                                </Grid>
                            ) : (<div></div>)
                        }
                        {
                            profile == "selfemp" ? (
                                <Grid item xs={12} p={2} alignItems='center' justifyContent='center'>
                                        <FormControl fullWidth sx={{mt:1}}>
                                    <InputLabel>Self Employeed Type</InputLabel>
                                    <Select
                                        value={residence}
                                        label='Self Employeed Type'
                                        fullWidth
                                        size='small'
                                        margin='dense'
                                        onChange={(e) => handleResidence(e)}
                                    >
                                        <MenuItem value='none'>None</MenuItem>
                                        <MenuItem value='irt'>IRT</MenuItem>
                                        <MenuItem value='gst'>GST</MenuItem>
                                        <MenuItem value='spouse'>Govt Other Proof</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                            ) : (<div></div>)
                        }
                        {
                            profile == "agriculture" ? (
                                <Grid container xs={12} alignItems='center' justifyContent='center' >
                                    <TextField type='number' variant='outlined' size='small'
                                        label='How Many Acrcs' margin='dense' />
                                </Grid>
                            ) : (<div></div>)
                        }
                        {
                            profile == "repayment" ? (
                                <Grid container columnGap={2} alignItems='center' justifyContent='center'>
                                    <TextField type='number' margin='dense' size='small' label='Existing Loan Amout' variant='outlined' />
                                    <TextField type='number' margin='dense' size='small' label='Existing EMI' variant='outlined' />
                                    <TextField margin='dense' type='number' size='small' label='Tenure Completed' variant='outlined' />
                                </Grid>
                            ) : (<div></div>)
                        }
                        </Grid>

                        {/* <Grid container >
                            <Grid item xs={12} md={6}>
                                <FormControlLabel control={<Checkbox onClick={() => setSalaried(!salaried)} checked={salaried} />} label="Salaried" />
                                {
                                    salaried ? (
                                        <div>
                                            <TextField type='number' variant='outlined' size='small' label='Monthly Income' />
                                            <TextField type='number' variant='outlined' size='small' label='Existing EMI' margin='dense' />
                                        </div>
                                    ) : (<div></div>)

                                }
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel sx={{ ml: 5 }} control={<Checkbox onClick={() => setSelf(!self)} checked={self} />} label='Self Employeed' />
                                {
                                    self ? (
                                        <div>
                                            <Grid item xs={12} p={2}>
                                                <FormGroup row>
                                                    <FormControlLabel control={<Checkbox checked={rent === 'itr'} onChange={() => handlecheckbox('itr')} />} label="ITR" />
                                                    <FormControlLabel control={<Checkbox checked={rent === 'gst'} onChange={() => handlecheckbox('gst')} />} label="GST" />
                                                    <FormControlLabel control={<Checkbox checked={rent === 'idproof'} onChange={() => handlecheckbox('idproof')} />} label="Govt Other Proof" />
                                                    <FormControlLabel control={<Checkbox checked={rent === 'none'} onChange={() => handlecheckbox('none')} />} label="none" />
                                                </FormGroup>
                                            </Grid>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                }
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel sx={{ ml: 1 }} label='Agriculture' control={<Checkbox checked={agri} onClick={() => setAgri(!agri)} />} />
                                {
                                    agri ? (
                                        <div>
                                            <TextField type='number' variant='outlined' size='small'
                                                label='How Many Acrcs' />
                                        </div>
                                    ) : (<div></div>)
                                }
                            </Grid>
                        </Grid> */}

                        {/* <Grid item xs={12}>
                            <Typography variant='subtitle1' sx={{ fontWeight: 'bold', textAlign: 'start', color: '#FF6000' }}>Payment Program</Typography>

                            <FormControlLabel control={<Checkbox checked={payments} onChange={() => setPayments(!payments)} />} label='Repayment Program' />
                            {
                                payments ? (
                                    <Grid item xs={12} columnGap={2}>
                                        <Grid item xs={12} alignItems='center' justifyContent='center'>

                                            <FormControlLabel control={<Checkbox />} label="Existing Loan Amout" />

                                        </Grid>

                                        <TextField type='number' margin='dense' size='small' label='Existing EMI' variant='outlined' />
                                        <TextField margin='dense' type='number' size='small' label='Tenure Completed' variant='outlined' />
                                    </Grid>
                                ) : (<div></div>)
                            }
                        </Grid> */}

                        {/* <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox />} label="None" />
                            </Grid> */}

                            {/* First Half */}
                            {/* <Grid item xs={12}>
                                        <Autocomplete
                                            sx={{ mt: 1 }}
                                            size='medium'
                                            freeSolo
                                            options={details.age.map((option) => option)}
                                            renderInput={(params) => <TextField  variant='standard'{...params} label="Select Your Age" />}
                                        />
                                    </Grid> */}
                            {/* <Grid item xs={12}>
                                    <Autocomplete
                                        sx={{ mt: 1 }}
                                        size='medium'
                                        freeSolo
                                        options={details.profile.map((option) => option)}
                                        renderInput={(params) => <TextField variant='standard'{...params} label="Select Customer Profile" />}
                                    />
                                </Grid> */}
                            {/* <Grid item xs={12}>
                                    <Autocomplete
                                        size='medium'
                                        freeSolo
                                        options={details.salary.map((option) => option)}
                                        renderInput={(params) => <TextField {...params} variant='standard' label="Select Monthly Income" />}
                                    />
                                </Grid> */}
                            {/* <Grid item xs={12}>
                                        <TextField 
                                        sx={{mt:1}}
                                            fullWidth
                                            variant='standard'
                                            label="Select Existing EMI" />
                                       
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField sx={{mt:1}} fullWidth 
                                         variant='standard' label="Select Max Emi Affordability" />
                                    </Grid> */}
                            <Grid container xs={12} mt={2}>
                                <Typography textAlign='start' sx={{ color: '#FF6000' }} fontWeight='bold'>
                                    Residence Type</Typography>

                                <FormControl fullWidth sx={{mt:1}}>
                                    <InputLabel>Select Residence Type</InputLabel>
                                    <Select
                                        value={residence}
                                        label='Select Residence Type'
                                        fullWidth
                                        sx={{mt:1}}
                                        size='small'
                                        margin='dense'
                                        onChange={(e) => handleResidence(e)}
                                    >
                                        <MenuItem value='own'>Own</MenuItem>
                                        <MenuItem value='rent'>Rent</MenuItem>
                                        <MenuItem value='leased'>Leased</MenuItem>
                                        <MenuItem value='emptyplot'>Empty Plot</MenuItem>
                                    </Select>
                                </FormControl>
                                {
                                    residence == 'own' ? (
                                        <Grid item xs={12} mt={1}>
                                            <FormControl fullWidth >
                                    <InputLabel>Own Type</InputLabel>
                                    <Select
                                        label='Own Type'
                                        sx={{mt:1}}
                                        fullWidth
                                        size='small'
                                        margin='dense'
                                    >
                                        <MenuItem value='slot'>Self</MenuItem>
                                        <MenuItem value='parent'>Parent</MenuItem>
                                        <MenuItem value='spouse'>Spouse</MenuItem>
                                    </Select>
                                </FormControl>
                                        </Grid>
                                    ) : (<div></div>)
                                }
                                {/* <Grid item xs={12} >
                                        <Typography textAlign='start' sx={{ color: '#FF6000' }} fontWeight='bold'>
                                            Residence Type</Typography>
                                        <FormGroup
                                            row>
                                            <FormControlLabel value="own" control={<Checkbox checked={own} onChange={() => setOwn(!own)} />} label="Own" />
                                            <FormControlLabel value="rent" control={<Checkbox checked={leased === 'rent'} onChange={() => handleCheckLeased('rent')} />} label="Rent" />
                                            <FormControlLabel value="leased" control={<Checkbox checked={leased === 'leased'} onChange={() => handleCheckLeased('leased')} />} label="Leased" />
                                        </FormGroup>
                                        {
                                            own ? (
                                                <Grid item xs={12}>
                                                    <Typography textAlign='start' fontWeight='bold'>
                                                        Own Type</Typography>
                                                    <FormGroup row>
                                                        <FormControlLabel control={<Checkbox checked={slot === 'Slot'} onChange={() => handlecheckSlot('Slot')} />} label='Slot' />
                                                        <FormControlLabel control={<Checkbox checked={slot === 'Parent'} onChange={() => handlecheckSlot('Parent')} />} label='Parent' />
                                                        <FormControlLabel control={<Checkbox checked={slot === 'Spouse'} onChange={() => handlecheckSlot('Spouse')} />} label='Spouse' />
                                                    </FormGroup>
                                                </Grid>
                                            ) : (<div></div>)
                                        }
                                    </Grid> */}
                                {/* <Grid item xs={12} md={4} >
                                        <Typography textAlign='start' fontWeight='bold'>
                                            Income Proof</Typography>
                                        <RadioGroup
                                            row
                                        >
                                            <FormControlLabel value="refinance" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="purchase" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid> */}
                            </Grid>


                            {/* Second Half */}
                            <Grid item xs={12}>

                                {/* <Grid item xs={12} container rowSpacing={1}>

                                     <Grid item xs={12}>
                                        <Typography fontWeight='bold' textAlign="start">
                                            Co-Applicant </Typography>
                                        <RadioGroup
                                            row
                                        >
                                            <FormControlLabel value="" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid> 

                                </Grid> */}

                                <Grid container item xs={12}>
                                    <Autocomplete
                                        sx={{ mt: 1 }}
                                        fullWidth
                                        size='small'
                                        freeSolo
                                        options={details.disticts.map((option) => option)}
                                        renderInput={(params) => <TextField fullWidth margin='dense' {...params} variant='outlined' required label="Select Place Of Stay" />}
                                    />
                                        <TextField
                                            autoComplete="given-name"
                                            variant='outlined'
                                            name="pan"
                                            size='small'
                                            margin='dense'
                                            type='number'
                                            required
                                            fullWidth
                                            id="pan"
                                            label="PAN Number"

                                        />
                                        <TextField
                                            variant='outlined'
                                            name="aadhar"
                                            type='number'
                                            size='small'
                                            required
                                            margin='dense'
                                            fullWidth
                                            id="aadhar"
                                            label="Aadhar Number"
                                        />
                                </Grid>
                            </Grid>
                        
                    </Grid>
                </Box>
            </Container>
        </div>


    )
}

export default Cibilprofile