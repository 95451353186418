import React from 'react';
import "./partners.css";
import { channels } from '../../dummydatas/channeldata';


export default function Partners(){
    return(
        <div className='container col-12'>
          
      
         <div className='row col-md-12 col-sm-3  partner-container d-flex justify-content-around'>
         <h4 className='session-heading'>Our Channel Partners</h4>
             {channels.map((data,i)=>{
               return(
                  <div key={i} className='branch'>
                 <img src=  {data.image} className='img-fluid  partner-images'/>
                     </div>
               )
             })}
           </div>
      </div>
    )
//     var settings = {
//         dots: true,
//         infinite: false,
//         speed: 500,
//         slidesToShow: 3,
//         slidesToScroll: 4,
//         initialSlide: 0,
//         responsive: [
//           {
//             breakpoint: 1024,
//             settings: {
//               slidesToShow: 3,
//               slidesToScroll: 3,
//               infinite: true,
//               dots: true
//             }
//           },
//           {
//             breakpoint: 600,
//             settings: {
//               slidesToShow: 2,
//               slidesToScroll: 2,
//               initialSlide: 2
//             }
//           },
//           {
//             breakpoint: 480,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1
//             }
//           }
//         ]
//     }
//    return(

//     <div>
//     <h3 className='text-center p-3'>Our Channel Partners</h3>
//     <div className='container'>
//         <div className='col-12 '/>
//         <Slider {...settings}>
     
//       <div>
//       <img src={bajaj} className='image-fluid'/>
//       </div>
//       <div>
//       <img src={equitas} className='image-fluid'/>
//       </div>
//       <div>
//       <img src={herofin} className='image-fluid'/>
//       </div>
//       <div>
//       <img src={icici} className='image-fluid'/>
//       </div>
//       <div>
//       <img src={ikf} className='image-fluid'/>
      
//       </div>
//         <div>
//         <img src={indusland} className='image-fluid'/>
//       </div>
//       <div>
    
      
//       </div>
//     </Slider>

//     </div>
// </div>
//    )
}