import tvs from "../asserts/partners/image1.png";
import equitas from "../asserts/partners/image2.png";
import mahindra from "../asserts/partners/image3.png";
import indusland from "../asserts/partners/image4.png";
import cholla from "../asserts/partners/image5.png";
import piramal from "../asserts/partners/image6.png";
import yesbank from "../asserts/partners/image7.png";
import herofin from "../asserts/partners/image8.png";
import tata from "../asserts/partners/image9.png";
import hinduja from "../asserts/partners/image10.png";
import ikf from "../asserts/partners/image11.png";
import kotak from "../asserts/partners/image12.png";
import manapuram from "../asserts/partners/image13.png";
import hdfc from "../asserts/partners/image14.png";
import icici from "../asserts/partners/image15.png";
import mahaveer from "../asserts/partners/image16.png";
import durga from "../asserts/partners/image17.png";
import hdb from "../asserts/partners/image18.png";
import indostar from "../asserts/partners/image19.png";
import shriram from "../asserts/partners/image20.png";
import bajaj from "../asserts/partners/image21.png";
export const channels =[

    {
        image:tvs
    },
    
    {
        image:equitas
    },
    
    {
        image: mahindra 
    },
    
    {
        image:indusland
    },
    
    {
        image:cholla
    },
    
    {
        image:piramal
    },
    
    {
        image:yesbank
    },
    
    {
        image:herofin
    },
    
    {
        image:tata
    },
    
    {
        image:hinduja
    },
    
    {
        image:ikf
    },
    
    {
        image:kotak
    },
    
    {
        image:manapuram
    },
    
    {
        image:hdfc
    },
    
    {
        image:icici
    },
    
    {
        image:mahaveer
    },
    
    {
        image:durga
    },
    
    {
        image:hdb
    },
    
    {
        image:indostar
    },
    
    {
        image:shriram
    },
    
    {
        image:bajaj
    },
    

]