import React ,{useState} from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import "./Eligibility.css";

function Eligibility(){
    const [key, setKey] = useState('tab2')
  
 
    return (
      <div className="tab-container tabs mx-auto">
        <Tabs className="Tabs" activeKey={key} onSelect={(k) => setKey(k)}>
          <TabList>
            <Tab eventKey="tab1">Self Employed</Tab>
            <Tab eventKey="tab2">Salaried Employee</Tab>
            <Tab eventKey="tab3">Used Car Features  Advantages</Tab>
          </TabList>

          <TabPanel>
            {/* <h3>For Self Employed Individuals</h3> */}
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>Aged Between 23-65 years</span>
            </p>
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>Minimum 2 years of Business Continuity</span>
            </p>
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>Tenure duration 12months-84 months</span>
            </p>
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>Residence stability 2 year in the current address and atleast 2 years in the same city </span>
            </p>
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>No Stability required in case of own house</span>
            </p>
        
          </TabPanel>

          <TabPanel>
            {/* <h3>Tab 2 working!</h3> */}
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>Aged Between 21-60 years</span>
            </p>
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>Minimum 2 years experience</span>
            </p>
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>Minimum salary 18000 per month</span>
            </p>
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>Tenure duration 12months to 84 month</span>
            </p>
          </TabPanel>
          <TabPanel>
            {/* <h3>Tab 3 is working!</h3> */}
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>Covering various cars,Muv and premium under pre owned car loans</span>
            </p>
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>loan available for vehicle upto 15years old</span>
            </p>
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>low rates of interest as compared to other unsecured loans</span>
            </p>
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>T-board on words  </span>
            </p>
            <p>
              <i className="fa-solid fa-square-up-right"></i>
              <span>settlement 24/7</span>
            </p>
          </TabPanel>
        </Tabs>
      </div>
    );
}

export default Eligibility;

